.carousel-container5 {
  height: 750px;
  overflow: hidden;
  color: black !important;
  display: flex;
  align-items: center;
  justify-items: space-evenly;
}

.backgroundImageOurProduct{
  background: url("../Assets/White_bg.png");
  background-size:cover;
  width: 100%;
  height: 750px;
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
}

.carousel5 {
  display: flex;
  transition: transform 0.5s ease-in-out;
  justify-content: center;
}

.card {
  width: 250px;
  height: 215px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 36px;
  perspective: 2500px;
  margin: 0 15px;
  border: none !important;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapper {
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  z-index: -1;
  border: 2px solid rgb(41, 255, 41);
  border-radius: 4px;
  height: 350px;
  box-shadow: rgba(58, 136, 53, 0.25) 0px 54px 55px, rgba(56, 225, 37, 0.12) 0px -12px 30px, rgba(67, 188, 37, 0.12) 0px 4px 6px, rgba(59, 217, 31, 0.17) 0px 12px 13px, rgba(78, 212, 38, 0.09) 0px -3px 5px;
}

.card:hover .wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(34, 241, 90, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(34, 241, 90, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(34, 241, 90, 0.75);
}

.wrapper::before,
.wrapper::after {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}
.wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(
    to top,
    transparent 1%,
    rgba(247, 250, 248, 0.75) 1%,
    rgba(250, 253, 251, 0.75) 1%
  );
}
.wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(
    to bottom,
    transparent 96%,
    rgba(34, 241, 90, 0.75) 98%,
    rgba(34, 241, 90, 0.75) 56%
  );
}

.card:hover .wrapper::before,
.wrapper::after {
  opacity: 1;
}

.card:hover .wrapper::after {
  height: 120px;
}
.title {
  width: 100%;
  transition: transform 0.5s;
}
.card:hover .title {
  transform: translate3d(0%, -50px, 100px);
}

.arrowHover {
  width: 80px;
  transition: all 0.7s;
  background-color: white;
}
@media (max-width: 650px) {
  .arrowHover{
    display: none !important;
  }
}

.arrowHover:hover {
  width: 80px;
  background-color: #ffac13c7;
}

.character {
  width: 100%;
  opacity: 0;
  height: 300px !important;
  transition: all 0.5s;
  position: absolute;
  z-index: -1;
}

@media (max-width:500px) {
  .character {
    height: auto !important;
   
  }
  
}
.card:hover .character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}

@media (min-width: 1024px) {
  .mobile-view {
    display: none !important;
  }
  .desktop-view {
    display: flex !important;
  }
}

@media (max-width: 1024px) {
  .mobile-view {
    display: flex !important;
  }
  .desktop-view {
    display: none !important;
  }
}

@keyframes move {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(5px);
  }
}

.product-name {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  padding: 8px 0 51px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  top: 172px;
}


