@import "rsuite/dist/rsuite.css";
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

html,
body {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
    cursor: url('../src/Assets/orange.cur'), auto;
  
  
}
html {
  scroll-behavior: smooth;
}

/* hamburger menu */
.no-scroll {
  overflow: hidden;
}

.flair {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  background: orange; 
  z-index: 11111;
}


.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 250px;
  z-index: 999;
  background-color: rgb(27, 27, 27);
  box-shadow: -10px 0 10px (rgba(0, 0, 0, 0.1));
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
}
.humburger-menu {
  display: none;
}

.sidebar .item {
  width: 100%;
}

@media (max-width: 1020px) {
  .items {
    display: none !important;
  }
  .humburger-menu {
    display: block;
  }
  .sidebar {
    display: flex;
    width: 100%;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown2 {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 3px;
  color: black;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: auto;
}

@media (max-width: 500px) {
  .logo {
    height: 50px !important;
    padding: 0 20px !important;
    display: flex;
  }
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 70%;
  margin: auto;
  overflow: hidden;
}

.carousel__image-container {
  position: relative;
}

.carousel__image {
  width: 100%;
  display: block;
}

.carousel__text {
  /* position: absolute; */
  /* bottom: 25%; */
  /* left: 5%; */
  /* height: 50%; */
  color: white;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  font-size: 1.2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (min-width: 990px) {
  .textVideo {
    margin-top: 100px !important;
  }
}
@media (max-width: 910) {
  .videoTitle {
    font-size: 20px !important;
  }
  .videoSubTitle {
    font-size: 15px !important;
    width: 100% !important;
  }
  .videoPhoneNumber {
    font-size: 17px !important;
  }
}
/* styles.css */
.carousel__text2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    135deg,
    rgba(0, 10, 21, 0.9),
    rgba(0, 10, 20, 0.25) 75%
  );
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.textName {
  z-index: 2;
  position: relative;
}

.carousel__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: white;
  z-index: 1;
}

.carousel__button--left {
  left: 10px;
}

.carousel__button--right {
  right: 10px;
}

.carousel__dots {
  text-align: center;
  margin-top: 10px;
}

.carousel__dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
}

.carousel__dot--active {
  background-color: #717171;
}

@media (max-width: 500px) {
  .navbarLogoContainer {
    padding: 0 0 !important;
  }
}
@media (max-width: 770px) {
  .FooterContainer {
    flex-direction: column !important;
  }
  .FooterSection {
    border-bottom: 1px solid grey !important ;
    padding: 10px 0 !important;
  }
}

@media (max-width: 1020px) {
  .cartListContainer {
    /* margin: 10px 100px !important; */
  }
  .DetailsImage{
    margin:0 !important;
  }
}

@media (max-width: 500px) {
  .cards {
    width: 200px !important;
  }
}
/* styles.css */

.cardListstyle {
  margin: 20px 200px;
}

@media (max-width: 1020px) {
  .cardListstyle {
    margin: 20px 50px !important;
  }
}

@media (min-width: 800px) {
  .PestControlImage {
    margin: 20px 100px !important;
    justify-content: center !important;
  }
}
@media (max-width: 600px) {
  .CardSizes {
    max-width: 90% !important;
  }
}

@media (max-width: 500px) {
  .PestControlDescription {
    margin: 0 !important;
  }
  .PestControlViewMoreButton {
    margin-top: 25px !important;
  }
  .PestControlTextContainer {
    margin: 10px !important;
  }
}
@media (max-width: 1200px) {
  .fabricationSection {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .fabricationBanner {
    width: 80% !important;
  }
}

@media (max-width: 850px) {
  .Fabricationsmallsection {
    flex-direction: column !important;
  }
}
@media (max-width: 850px) {
  .Fabricationsmallsection2 {
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 450px) {
  .smallimages {
    height: 150px !important;
    width: 300px !important;
  }
}

@media (max-width: 1000px) {
  .customerDiv {
    flex-direction: column !important;
  }
  .customSection {
    margin: 0px 0px 20px 0px !important ;
  }
}

.carousel5 {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide5 {
  display: flex;
  min-width: 100%;
}

.carousel-item5 {
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  background-color: white;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 220px;
}

.carousel-image5 {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.prev-btn5 {
  left: 0;
}

.next-btn5 {
  right: 0;
}
.RouteOptions:hover {
  color: #6bc24e !important;
  font-weight: 700 !important;
  text-decoration: none;
}
.RouteOptions2:hover {
  color: #6bc24e !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}
.options:hover {
  background: #6bc24e;
  color: white;
}

.Buttons:hover {
  color: #6bc24e !important;
  background: white !important;
}

@media (max-width: 1024px) {
  .aboutUsContainer {
    margin: 0 50px !important;
  }
  .ProductSideBar {
    display: none !important;
  }
  .ProductCardsContainer {
    width: 100% !important;
    text-align: center;
    display: flex;
    flex-direction: column !important;
  }
  .ItemCardsSection {
    justify-content: center !important;
  }
  .BannerImageCarousel {
    height: auto !important;
    width: 100% !important;
  }
  .fixed-bg2{
    height: auto !important;
  }
}

.HoverButtons:hover {
  color: white !important;
  background: #6bc24e !important;
}

/* @media (max-width: 680px) {
  .carousel__text .VideoHeading{
    font-style: 20px !important;
  }
}  */

@media (min-width: 850px) {
  .ActionButton {
    display: none !important;
  }
}
@media (max-width: 490px) {
  .ContactUsButton {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 550px) {
  .PestControlContainer {
    padding-top: 50px !important;
  }
}

/* ABOUT US */
@media (max-width: 800px) {
  .AboutusTitle {
    font-size: 20px !important;
  }
  .ProductlistImage{
    flex-direction: row !important;
  }
  .ProductListImageContainer{
    flex-direction: column-reverse !important;
  }
  .ProductListDescription{
    width: 95% !important;
    margin: 0 !important;
  }
}

@media (max-width: 500px) {
  .TopProductsTitle {
    margin-bottom: 20px !important;
  }
  .our-product-container {
    height: 450px !important;
  }
}
@media (min-width: 501px) and (max-width: 600px) {
  .TopProductsTitle {
    margin-bottom: 50px !important;
  }
  .our-product-container {
    height: 475px !important;
  }
}

@media (min-width: 601px) and (max-width: 780px) {
  .TopProductsTitle {
    margin-bottom: 20px !important;
  }
  .our-product-container {
    height: 450px !important;
  }
}

@media (max-width: 1024px) {
  .aboutUsContainer2 {
    flex-direction: column !important;
  }
  .AboutUsTextContainer {
    width: 100% !important;
    margin: 0 !important;
  }
  .aboutUsImage {
    width: 100% !important;
    height: auto !important;
  }
  .aboutUsContainerHome {
    flex-direction: column-reverse !important;
    height: auto !important;
  }
  .AboutMoreButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .our-mission {
    flex-direction: column !important;
  }
  .our-mission-section {
    width: 100% !important;
  }
  .TopProductssubTitle{
    width: 90% !important;
    margin-bottom: 20px !important;

  }
}
@media (max-width: 600px) {
  .aboutUsContainer2 {
    margin: 20px !important;
  }
}

.sideBarOption {
  background: transparent;
  transition: transform 0.5s;
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
  /* border-radius: 20px !important; */
}

.sideBarOption:hover {
  color: black !important;
  /* background: #569714; */
  cursor: pointer;
  /* background: url("../src/Assets/titleImage.jpg"); */
  background: rgb(255, 188, 63);
  background-size:contain;
  width: 100%;
  height:auto;
  transform: scale(1.05);
}
.ProductSideBar {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 12% !important;
  width: 20% !important;
  /* border-right: 1px solid black !important; */
  /* margin: 0 20px 0 0px !important; */
  height: 685px !important;
  z-index: 1111 !important;
}
.scrollable-section {
  width: 100%;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ProductCard {
  text-align: center;
  padding: 25px 10px;
  border-radius: 5px;
  cursor: pointer;
  background: transparent;
  transition: transform 0.5s, background 0.5s;
  border-top-right-radius: 150px;
  border-bottom-left-radius: 150px;
}

.ProductCard:hover {
  background: #65b200;
  color: #fff;
  transform: scale(1.02);
  border-top-right-radius: 150px;
  border-bottom-left-radius: 150px;
}

@media (max-width: 700px) {
  .DetailsDialogBox {
    flex-direction: column !important;
  }
  .DetailsImage {
    height: auto !important;
    width: 100% !important;
    margin: 0 !important;
  }
}

@media (max-width: 630px) {
  .css-1wf8b0h-MuiTabs-flexContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .css-16si5p1-MuiTabs-root .MuiTab-root {
    margin-bottom: 10px !important;
  }
  .css-1l4w6pd {
    display: flex !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
}
}

.aboutUs-image-div {
  position: relative;
}

.hoverimageName {
  display: block;
  width: 100%;
  height: auto;
}

.hoverNameText {
  position: absolute;
  bottom: 67px;
  border-bottom-right-radius: 58px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 97%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}

.aboutUs-image-div:hover .hoverNameText {
  opacity: 1;
}

.fixed-bg {
  position: relative;
  background-image: url("./Assets/White_bg.png");
  height: 750px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center !important;
  color: black;
}

.fixed-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-bg-content {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
  height: 100%;
}

.fixed-bg2 {
  position: relative;
  background-image: url("./Assets/White_bg.png");
  height: 700px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.fixed-bg2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-bg-content2 {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
  height: 100%;
}

.coolBeans {
  border: 2px solid currentColor;
  border-radius: 3rem;
  font-family: roboto;
  font-size: 4rem;
  font-weight: 100;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
  text-decoration: none;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;
}
.coolBeans::after {
  background-color: #F58634;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.2s transform ease-out;
  will-change: transform;
  z-index: -1;
}
.coolBeans:hover::after {
  transform: translate(0, 0);
}
.coolBeans:hover {
  border: 2px solid transparent;
  color: white !important;
  transform: scale(1.05);
  will-change: transform;
}

.fixed-bg-DetailsPage {
  position: relative;
  background-image: url("./Assets/Grass2.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center !important;
}

.fixed-bg-DetailsPage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-bg-content-DetailsPage {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
  height: 100%;
}

.MobileView{
  display: none !important;
}

.desktopView{
  display: flex;
}
@media (max-width:1024px) {
  .MobileView{
    display: flex !important;
  }
  .desktopView{
    display: none !important;
  }
  .HideContent{
    display: none !important;
  }
}

.backgroundImageOurProductPage{
  /* background: url("../src/Assets/White_bg.png"); */
  background-size:contain;
  width: 100%;
  height:auto;
  gap: 12px;
  
}

.ProductListTitle{
  /* background: url("../src/Assets/titleImage.jpg"); */
  background: rgb(255, 188, 63);
  background-size:contain;
  width: 100%;
  height:auto;
  border: 2px solid black;
  border-radius: 20px;
}

.ProductListCardsBg{
  /* background: url("../src/Assets/cardbg.jpg"); */
  background-size:cover;
  width: 100px;
  height:auto;
  /* opacity: 0.5; */
}
.waveBorder{
  -webkit-mask: conic-gradient(from -45deg at bottom, #0000, #000 1deg 90deg, #0000 90deg) 50% / 12px 100%;
  height: 40px;
}
.side-images-container img {
  border: 1px solid black;
  background-color: white;
  border-radius: 4px;
  padding: 5px;
  transition: transform 0.2s;
  margin-left: 100px;

}

.side-images-container img:hover {
  transform: scale(1.1);
}

.btn {
  width: 180px;
  height: 50px;
  background: linear-gradient(90deg, green 0%, orange 100%);
  font-size: 1em;
  font-weight: 600;
  border-radius: 10px;
  color: white !important;
  border: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 1em 2em;
  cursor: pointer;
  transition: all 500ms ease;
  box-shadow: 0 4px 6px -1px hsla(0, 0%, 0%, 0.1), 0 2px 4px -2px hsla(0, 0%, 0%, 0.1);
}

.btn:hover {
  background: linear-gradient(90deg,  orange 0%, green 100%);
}