.language-selector {
  position: relative;
  display: inline-block;
  width: 27%;
}

.language-selector-button {
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  color: black;
  margin-left: "60px";
}

.language-dropdown {
  width: 64%;
  border-radius: 2px;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  color: black;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.language-dropdown button {
  width: 100%;
  padding: 10px;
  text-align: left;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.language-dropdown button:hover {
  background-color: #f0f0f0;
}
