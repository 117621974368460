.carousel-container25 {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.carousel25 {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide25 {
  display: flex;
  min-width: 100%;
}

.carousel-item25 {
  flex: 1;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  background-color: white;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ddd;
  height: 270px;
  width: 270px;
}

.carousel-image25 {
  height: 200px;
  object-fit: contain;
}

.carousel-button25 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0092cb;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.carousel-button25:hover {
  opacity: 1;
}

.carousel-button25.prev25 {
  left: -40px;
}

.carousel-button25.next25 {
  right: -40px;
}
.MobileView {
  display: none;
}

.scroll-arrow25:hover {
  color: white !important;
}

@media (max-width: 1024px) {
  .MobileView {
    display: flex;
  }
  .desktopView {
    display: none !important;
  }
}

.fixed-bg-CertificatePage {
  position: relative;
  background-image: url("../../Assets/certificatebg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 450px;
  top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fixed-bg-CertificatePage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fixed-bg-content-CertificatePage {
  position: relative;
  z-index: 2;
  color: white;
  padding: 20px;
}
