.TeamImageBg {
  background: url("../../Assets/White_bg.png");
  background-size: contain;
  height: 841px;
  border-top:5px solid black ;
}
.OurTeamImages {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.OurTeamImages:hover {
  -webkit-transform: scale(1.3) !important;
  transform: scale(1.3) !important;
  z-index: 11111 !important;
}

@media (max-width: 1030px) {
  .OurTeamImagesMargin {
    margin-top: 15px !important;
  }
  .TeamImageBg {
    height: auto !important;
  }
}
@media (max-width: 500px) {
  .CardsStyles {
    width: 90% !important;
    height: auto !important;
    flex-direction: column !important;
  }
  .OurTeamImagesSize{
    width: 100% !important;
  }
}
