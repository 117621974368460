.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #4caf50;
  width: 100%;
  padding: 0px 42px;
}

.logo-img {
  height: 65px;
  width: 150px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  text-decoration: none;
  color: white;
  padding: 10px 15px;
  display: block;
}

.nav-links a:hover,
.nav-links .dropdown:hover > a {
  background: #45a049;
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  padding: 10px 0;
  list-style: none;
  min-width: 200px;
}

.dropdown-menu a {
  padding: 10px 20px;
  color: black;
  display: block;
  white-space: nowrap;
  text-decoration: none;
}

.dropdown-menu a:hover {
  background: #6bc24e;
  color: black;
}

.dropdown-menu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: 1px;
}

.dropdown:hover > .dropdown-menu,
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.nav-links2 {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links2 li {
  position: relative;
}

.nav-links2 a {
  text-decoration: none;
  color: white;
  padding: 10px 15px;
  display: block;
}

.nav-links a:hover,
.nav-links .dropdown2:hover > a {
  background: #45a049;
  cursor: pointer;
}

.dropdown-menu2 {
  display: none;
  position: absolute;
  top: 100%;
  left: -300px;
  background: white;
  border-radius: 5px ;
  padding: 10px 0;
  list-style: none;
  min-width: 790px;
}

.dropdown-menu2 a {
  padding: 10px 20px;
  color: black;
  display: block;
  white-space: nowrap;
  text-decoration: none;
}

.dropdown-menu2 a:hover {
  background: #6bc24e;
  color: black;
}

.dropdown-menu2 .dropdown-menu2 {
  top: 0;
  left: 100%;
  margin-left: 1px;
}

.dropdown2:hover > .dropdown-menu2,
.dropdown-submenu:hover > .dropdown-menu2 {
  display: block;
}

.dropdown2 i {
  margin-left: 5px;
}


.dropdown i {
  margin-left: 5px;
}

.right-icon {
  float: right;
  margin-left: 5px; /* Adjust margin as needed for spacing */
}

@import url('https://fonts.googleapis.com/css?family=Lato:100&display=swap');

.kisan-shopee-container {
  width: 180px;
  height: 60px;
  position: relative; /* Changed from absolute to relative */
  display: flex;
  justify-content: center;
  align-items: center;
}

.kisan-shopee-btn {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91C9FF;
  outline: none;
  position: relative; /* Added to position the SVG */
  animation: bgColorChange 3s infinite; /* Added animation */
}

@keyframes bgColorChange {
  0%, 100% {
    background: transparent;
  }
  50% {
    background: #4F95DA;
  }
}

.kisan-shopee-btn svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  animation: dashOffsetChange 3s infinite; /* Added animation */
}

@keyframes dashOffsetChange {
  0%, 100% {
    stroke-dashoffset: 150;
  }
  50% {
    stroke-dashoffset: -480;
  }
}

.kisan-shopee-btn span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}