
.container {
    width: 100%;
    height: auto;
    padding: 0px 8%;
  }
  
  .container h1 {
    text-align: center;
    padding-top: 7%;
    margin-bottom: 60px;
    font-weight: 600;
    position: relative;
  }
  
  .container h1::after {
    content: '';
    background: #04c237;
    width: 40%;
    height: 5px;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .card-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(415px, 1fr));
    grid-gap: 30px;
    width: 100%;
    margin-bottom: 4%;
  }
  
  .service {
    text-align: center;
    padding: 25px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background-color: #defbb8;
    transition: transform 0.5s, background 0.5s;
    border-top-right-radius: 150px;
    border-bottom-left-radius: 150px;
  }
  @media (max-width:500px) {
    .service {
      padding: 0 !important;
    }
    .smallImages{
      width: 90% !important;
      height: auto !important;
    }
    .card-row {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    }
    
  }
  
  .service .service_heading {
    font-weight: 600;
    margin-bottom: 8px;
    text-transform: capitalize;
  }
  
  .service:hover {
    background-color: #65B200;
    color: #fff;
    transform: scale(1.05);
    border-top-right-radius: 150px;
    border-bottom-left-radius: 150px;
  }
  
  .service:hover i {
    color: #fff;
  }
  
  .service_para {
    margin: 30px 0;
    text-transform: capitalize;
  }
  
  .service_btn {
    padding: 10px 40px;
    border: 1px solid black;
    border-radius: 10px;
    text-decoration: none;
    color: black;
  }
  
  .service_btn:hover {
    color: #fff;
  }