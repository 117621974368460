.carousel10 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.slide10 {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  position: relative; 
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out !important;
  opacity: 0;
  transform: scale(0.95);
}

.arrowHover1 {
  width: 80px;
  height: 80px;
  transition: all 0.7s;
  background-color: white;
  margin-top: 180px;
}

.arrowHover1:hover {
  width: 80px;
  height: 80px;
  background-color: #ffac13c7;
  margin-top: 180px;
}

.active10 {
  opacity: 1;
  transform: scale(1);
}

.inactive10 {
  opacity: 1;
  transform: scale(0.70);
}

.color-wrapper {
  position: relative; 
  padding: 20px; 
  height: 467px;
}

.bg-shape {
  background: green;
  border-top-right-radius: 24%;
  border-bottom-left-radius: 20%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.image10 {
  width: 300px !important;
  height: 300px !important;
  padding: 8%;
  border-radius: 30px !important;
}

.color10 {
  filter: grayscale(100%);
}

.bw10 {
  filter: grayscale(100%);
}

.member-info10 {
  margin-top: 10px;
}

.prev10, .next10 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  outline: none;
  color: white;
  font-size: 24px;
}

.prev10 {
  left: 10px;
}

.next10 {
  right: 10px;
}

.bgImage {
  background: url("../Assets/White_bg.png");
  background-size: contain;
  height: 700px;
}
