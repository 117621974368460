.footer-logo {
  /* width: 190px; */
  height: 115px;
  cursor: pointer;
}

.footer-title {
  font-size: 26px;
  font-weight: 800;
  padding: 24px;
  letter-spacing: 3px;
  line-height: 1.3em;
}

.FooterContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-top: 30px;
}

.FooterSection {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  line-height: 1.5;
}

.FooterSectionEmail {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
}

.footer-heading {
  font-size: 21px;
  font-weight: 800;
}

.footer-link {
  font-size: 20px;
  color: black;
  cursor: pointer;
  text-decoration: none;
}
.footer-email {
  display: flex;
  font-size: 20px;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
  background-color: white;
}

.social-icon {
  color: black;
  margin-right: 10px;
  cursor: pointer;
}

.wheat-icons {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.wheat-icon {
  width: 50px;
  height: auto;
  position: absolute;
  bottom: 0;
  animation: moveLeaves 10s linear infinite;
  opacity: 0;
}

.wheat-icon1 {
  left: 10%;
  animation-delay: 0s;
}

.wheat-icon2 {
  left: 30%;
  animation-delay: 2s;
}

.wheat-icon3 {
  left: 50%;
  animation-delay: 4s;
}

.wheat-icon3 {
  left: 100%;
  animation-delay: 6s;
}

@keyframes moveLeaves {
  0% {
    transform: translate(0, 0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(200px, -200px) rotate(360deg);
    opacity: 0;
  }
}

@media (max-width: 770px) {
  .footer {
    height: auto !important;
  }
}