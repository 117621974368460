/* Gallery Collage Section */
.galleryCollageSectionDiv {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 50px 0px;
}

.titleText {
  text-align: center;
  color: #00a550;
  font-size: 50px;
}
.galleryCollageSection {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.leftImage,
.centerImage,
.rightImage {
  border-radius: 6px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  cursor: pointer;
}

.leftImage,
.rightImage {
  width: 300px;
  height: 400px;
  opacity: 0.7;
}

.centerImage {
  width: 660px;
  height: 450px;
  opacity: 1;
  transform: scale(1.1);
}

.leftImage:hover,
.rightImage:hover {
  transform: scale(1.05);
  opacity: 0.85;
}

.centerImage:hover {
  transform: scale(1.15);
}

/* Slider section */
.sliderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.sliderArrow {
  background-color: green;
  border: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 10px;
  transition: background-color 0.3s ease;
  width: 55px;
}

.sliderArrow:hover {
  background-color: green;
}

.sliderSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail {
  height: 100px;
  width: 120px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 6px;
  transition: transform 0.3s ease;
}

.thumbnail.active {
  transform: scale(1.1) !important;
  border: 3px solid green !important;
}

.thumbnail:hover {
  transform: scale(1.05);
}

/* Gallery Responsiveness */
@media (max-width: 1000px) {
  .galleryCollageSection {
    flex-direction: column;
  }

  .leftImage,
  .rightImage,
  .centerImage {
    width: 100%;
    height: auto;
  }

  .sliderSection {
    flex-wrap: wrap !important;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .galleryCollageSectionDiv {
    height: auto !important;
  }

  .titleText {
    font-size: 48px !important;
  }

  .galleryCollageSection {
    width: 100% !important;
  }

  .thumbnail {
    height: 80px !important;
    width: 90px !important;
  }

  .sliderArrow {
    width: 40px !important;
    font-size: 20px !important;
  }

  .sliderSection {
    flex-wrap: wrap !important;
    gap: 10px;
  }
}

@media (max-width: 630px) {
  .galleryCollageSection img {
    width: 76% !important;
    height: auto !important;
  }
}

@media (max-width: 425px) {
  .titleText {
    font-size: 36px !important;
  }

  .thumbnail {
    height: 60px !important;
    width: 70px !important;
    margin: 0 5px !important;
  }

  .sliderArrow {
    width: 30px !important;
    font-size: 18px !important;
  }

  .galleryCollageSection img {
    width: 100% !important;
    height: auto !important;
  }

  .sliderSection {
    flex-wrap: wrap !important;
    gap: 10px;
  }
}

@media (max-width: 1150px) {
  .rightImage,
  .leftImage {
    display: none !important;
  }
}
