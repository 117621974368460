@keyframes blinkShadow {
    0% {
      box-shadow: 0 4px 10px rgb(0, 255, 0); 
    }
    50% {
      box-shadow: 0 6px 40px rgba(0, 255, 0, 1); 
    }
    100% {
      box-shadow: 0 4px 10px rgb(0, 255, 0); 
    }
  }
  
  .whatsapp-button {
    animation: blinkShadow 1s infinite; /* Adjust duration as needed */
  }
  